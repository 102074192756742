// src/components/_common/DeleteConfirmationDialog/index.js
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import styles from './css/delete-confirmation-dialog.module.css';

export const DeleteConfirmationDialog = ({ open, handleClose, handleConfirm }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage 
                    id="DeleteThisItem" 
                    defaultMessage="Usunąć tę pozycję?" 
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage 
                        id="AreYouSureYouWantToDelete" 
                        defaultMessage="Czy na pewno chcesz usunąć tę pozycję?" 
                    />
                    {/* <div className="horizontal-line"></div> */}
                </DialogContentText>
                    <div className="indented-line"></div>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage 
                        id="YouCannotUndoThisAction" 
                        defaultMessage="Tego działania nie można cofnąć." 
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={styles["delete-confirmation-dialog-btn"]}>
                    <FormattedMessage 
                        id="Cancel" 
                        defaultMessage="Anuluj" 
                    />
                </Button>
                <Button onClick={handleConfirm} color="primary" autoFocus className={styles["delete-confirmation-dialog-btn"]}>
                    <FormattedMessage 
                        id="Delete" 
                        defaultMessage="Usuń" 
                    />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// PropTypes
DeleteConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
  // Definicje dla pozostałych właściwości...
};
