// src/components/CardsGrid/index.js
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Image from 'next/image';
import { useRouter as useNextRouter } from 'next/router';
import { useRouter as useNavigationRouter } from 'next/navigation';
import PropTypes from 'prop-types';
import { useSession } from 'next-auth/react';
import { FaPlusCircle, FaHeart, FaInfoCircle, FaTrashAlt, FaEye, FaPencilAlt } from 'react-icons/fa'; //FaArrowsAltH, FaThList, FaThLarge, FaTh, FaListUl, FaSearchPlus 
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useIntl } from 'react-intl';

import styles from './css/cards-grid.module.css';

import { ImagePaths, IconPaths, Base64Paths } from '@/constants';
import { createSitemapUrl, convertDistance, detectCapitalLetters, getSourceIcon, usePrevious } from '@/utils';
import fetchBookOffers from '@/lib/redux/bookOffers/ApiActionCreator';
import fetchUserBookOffers from '@/lib/redux/userBookOffers/ApiActionCreator';
import fetchSimilarBookOffers from '@/lib/redux/similarBookOffers/ApiActionCreator';
// import { resetPolandActivities } from '../../lib/redux/bookInformation/ApiAction';
import { genAltText } from '@/utils';
import { useLanguage } from '@/contexts/LanguageContext';
import { useFavorite } from '@/contexts/FavoritesContext';
import { useGeolocation } from '@/contexts/GeolocationContext';
// import fetchUserFavoritesBookOffers from '../../lib/redux/userFavoritesBookOffers/ApiActionCreator';
import Pagination from '@/components/_common/Pagination';
import { useAvailableSoon } from '@/contexts/AvailableSoonContext';
import { DeleteConfirmationDialog } from '@/components/_common/DeleteConfirmationDialog';
import { CardDetailsModal } from '@/components/CardDetailsModal';
import { Trans } from '@/utils/intlTranslations';
import { jsonArrayToObject } from '@/utils';
import { setBookOffersId, resetBookOffersId } from '@/lib/redux/bookOffersSlice';
import { extractBookInformationDetails } from '@/lib/api/extractBookInformationDetails';

import Loading from "@/components/_common/Loading/Loading";

import debugLog from '@/helpers/debugLog'; 

export const CardsGrid = ({searchPhrase, cardsGridData, dataReducer, sortFieldAndDirection, limitNumberColumns = false}) => {
    // debugLog('CardsGrid cardsGridData', 'debug', cardsGridData);
    const { changeIsShownState } = useAvailableSoon();
    const [cardIndex, setCardIndex] = useState(0);
    const [page, setPage] = useState(1);
    const [currentCardsGridData, setCurrentCardsGridData] = useState([]);
    const {locale} = useLanguage();
    // const { favorites, toggleFavorite, isFavorite } = useFavorite();
    const dispatch = useDispatch();
    const routerNextRouter = useNextRouter();
    const routerNavigationRouter = useNavigationRouter();
    // Dostęp do bieżącej ścieżki
    // const currentPathname = routerNextRouter.asPath;
    const currentHostname = window.location.hostname;
    const originDomain = `${window.location.protocol}//${window.location.host}`;
    const { data: session } = useSession(); // Pobranie sesji
    const { coordinates } = useGeolocation();
    // const [debouncedCardIndex] = useDebounce(cardIndex, 800);
    const isMyCollectionPage = routerNextRouter.pathname === '/moj-zbior';
    const [loading, setLoaging] = useState(false);
    // Lokalny stan do przechowywania sortField i sortDirection
    const [localSortState, setLocalSortState] = useState(sortFieldAndDirection);

    const intl = useIntl();
    const processing = intl.formatMessage({ id: "Processing" });

    // Selektor Reduxa 
    const dataMap = {
        bookInformation: useSelector((state) => state.bookInformation),
        bookOffers: useSelector((state) => state.bookOffers),
        recentlyViewedBookOffers: useSelector((state) => state.recentlyViewedBookOffers),
        userBookOffers: useSelector((state) => state.userBookOffers),
        similarBookOffers: useSelector((state) => state.similarBookOffers),
        userFavoritesBookOffers: useSelector((state) => state.userFavoritesBookOffers),
    };
    const sessionData = useSelector((state) => state.session.user);

    const booksIsLoading = dataMap[dataReducer]?.loading ?? null; 

    const deleteBookOffers = useCallback(async (id) => {
        setLoaging(true);
        try {
          const response = await fetch(`/api/user-book-offers/${id}`, {
            method: 'DELETE',
          });
      
          if (!response.ok) {
            setLoaging(false);
            throw new Error('Błąd podczas usuwania lokalizacji');
          }
      
            const result = await response.json();
            // console.log(result);
            setLoaging(false);
            toast.success(
                <FormattedMessage
                    id = "ItemDeletedSuccessfully"
                    defaultMessage="Pozycja została pomyślnie usnunięta."
                />
            ) 

            setTimeout(()=> {
                // odświeżenie stanu moj-zbior
                if (session?.user) {
                    dispatch(fetchUserBookOffers(session.user.id, searchPhrase, localSortState.sortField, localSortState.sortDirection, 1));
                }
                // routerNextRouter.push('/moj-zbior'); 
                // routerNextRouter.replace('/moj-zbior');
            }, 2000);
          // Dodatkowe działania, np. aktualizacja UI po usunięciu
        } catch (error) {
            console.error(error);
            setLoaging(false);
            toast.error(
                <FormattedMessage
                    id = "LocationDeletedError"
                    defaultMessage="Przepraszamy, usunięcie pozycji nie powiodło się z powodu błędu!"
                />
            )
        }
    }, [searchPhrase, localSortState, dispatch, session]); 

    const getCategoryList = useCallback((category) => {
        // debugLog('CardsGrid getCategoryList', 'debug', category);
        if ( category.length > 0 ) {
            return (
                <div className={styles["cg--cat-l"]}>
                    {category.map((item, index) => ( 
                        <div key={index + item.id} className={styles["cg--cat-i"]}>
                            { item.name }
                        </div>
                    ))}
                </div>
            )
        }else {
            return <div className={styles["cg--empty-l"]}></div>
        }
    }, []);

    const getISBN = useCallback((card) => {
        if (card) {
            return card.isbn || card.isbn_13 || card.isbn_10 || card.ean || null;
        }
    }, []); 
    
    const getLocationName = useCallback((location, maxLength = 30) => {
        // Sprawdza, czy pozostały jakiekolwiek części nazwy lokalizacji; jeśli nie, zwraca alternatywny tekst
        if (location.name.length === 0) {
            return React.createElement('span', { style: { fontSize: '16px', fontWeight: '300', color: 'red' }}, 'brak nazwy lokalizacji'); 
        }
        return location.name.length > maxLength ? location.name.substring(0, maxLength) + '...' : location.name;
    }, []); 

    const goToCardDetailsPage = useCallback((card, lang) => {
        // Resetowanie stanu z id książki do edycji
        dispatch(resetBookOffersId());

        // debugLog('CardsGrid goToCardDetailsPage', 'debug', card);
        const cardDetailsUrl = createSitemapUrl(card, lang);

        const currentSubdomain = currentHostname.split('.')[0];
        const allowedSubdomains = ['buuki', 'szukajksiazki', 'localhost'];
    
        if (!allowedSubdomains.includes(currentSubdomain)) {
            const cardDetailsUrlWithDomain = new URL(cardDetailsUrl, originDomain).pathname;
            routerNavigationRouter.push(cardDetailsUrlWithDomain);
        } else {
            routerNavigationRouter.push(cardDetailsUrl);
        }
    }, [currentHostname, originDomain, routerNavigationRouter, dispatch]); 

    const handleRouteChange = useCallback((url) => {
        const parsedUrl = new URL(url, originDomain); // Dodaj drugi argument jako bazę, jeśli url jest względny
        const newUrl = parsedUrl.toString();
        window.history.replaceState(null, '', newUrl);
    }, [originDomain]);

    const showMoreCards = useCallback(() => {
        // debugLog('CardsGrid showMoreCards', 'debug', cardIndex + "|" + page);
        setCardIndex(cardIndex + 6);
        setPage(page + 1);
    }, [cardIndex, page]);
    
    // Nasłuchuje zmiany trasy (route) na stronie i obsługi tych zmian
    useEffect(() => {
        const currentSubdomain = currentHostname.split('.')[0];
        if (currentSubdomain !== 'localhost') {
            // Listen to route changes and handle them accordingly
            const events = ['routeChangeStart', 'beforeHistoryChange'];
            for (const event of events) {
                routerNextRouter.events.on(event, handleRouteChange);
            }

            return () => {
                for (const event of events) {
                    routerNextRouter.events.off(event, handleRouteChange);
                }
            };
        }
    }, [routerNextRouter.events, currentHostname, handleRouteChange]);

    // Resetowanie currentCardsGridData do początkowego stanu (cardsGridData) za każdym razem, 
    // gdy wartość page wraca do 1.
    // Zapewnia, że currentCardsGridData jest zsynchronizowany z cardsGridData na pierwszej stronie.
    useEffect(() => { 
        if ( page === 1 ) {
            // debugLog('CardsGrid currentCardsGridData page === 1', 'debug', currentCardsGridData);
            setCurrentCardsGridData(cardsGridData);
        }
    }, [cardsGridData, page]);

    // Asynchroniczne do pobierania danych 
    // fetchUserBookOffersData i ... w zależności od `dataReducer`
    useEffect(() => {
        // Funkcje asynchroniczne do pobierania danych
        function fetchUserBookOffersData(userId, huntfor, sortField, sortDirection, page) {
            dispatch(fetchUserBookOffers(userId, huntfor, sortField, sortDirection, page));
        }

        function fetchBookOffersData(latitude, longitude, huntfor, page) {
            dispatch(fetchBookOffers(latitude, longitude, huntfor, page));
        }
    
        function fetchSimilarBookOffersData(latitude, longitude, bookOfferId, page) {
            dispatch(fetchSimilarBookOffers(latitude, longitude, bookOfferId, page));
        }
    
        // Pobieranie danych w zależności od `dataReducer`
        if (dataReducer === 'userBookOffers' && session?.user) {
            fetchUserBookOffersData(session.user.id, searchPhrase, localSortState.sortField, localSortState.sortDirection, page);
        }

        // Pobieranie danych w zależności od `dataReducer`
        if (dataReducer === 'bookOffers') {
            fetchBookOffersData(coordinates.latitude, coordinates.longitude, searchPhrase, page);
        } else if (dataReducer === 'similarBookOffers') {
            const bookOfferId = window.location.href.split('/').pop(); // alternatywny sposób pobrania ID
            fetchSimilarBookOffersData(coordinates.latitude, coordinates.longitude, bookOfferId, page);
        }
    }, [cardIndex, coordinates, dataReducer, searchPhrase, localSortState, session, page, dispatch]);

    // Aktualizuje currentCardsGridData o unikalne książki z cardsGridData, wykluczając powtórzenia na podstawie id
    useEffect(() => {
        // debugLog('CardsGrid currentCardsGridData', 'debug', currentCardsGridData);
        const newBook = cardsGridData.filter(book => 
            book != null && !currentCardsGridData.some(existingBook => 
                existingBook.id === book.id)
        );
    
        if (newBook.length > 0) {
            setCurrentCardsGridData(prevData => {
                const uniqueNewBooks = newBook.filter(newBook => 
                    !prevData.some(existingBook => 
                        // existingBook.title === newBook.title && 
                        // existingBook.authors === newBook.authors
                        existingBook.id === newBook.id
                    )
                );
    
                if (uniqueNewBooks.length > 0) {
                    return [...prevData, ...uniqueNewBooks];
                }
    
                return prevData;
            });
        }
    }, [cardsGridData, currentCardsGridData]);

    // Efekt wykonywany przy zmianie sortFieldAndDirection
    useEffect(() => {
        setLocalSortState(sortFieldAndDirection);
        setPage(1); // Ustawia stronę na 1 za każdym razem, gdy zmienia się sortowanie
    }, [sortFieldAndDirection]);

    // 
    // Pagination dla dataReducer = 'userFavoritesBookOffers'
    // 
    // Ustawienia paginacji
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 6; // Liczba rekordów na stronę
    const nPages = Math.ceil(currentCardsGridData.length / recordsPerPage);

    // Obliczanie zakresu danych do wyświetlenia
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = currentCardsGridData.slice(indexOfFirstRecord, indexOfLastRecord);
    // 
    // END Pagination
    // 

    const renderButtonOrPagination = () => {
        if (booksIsLoading) {
            return (
                <div className={styles["spn-cnt"]}>
                    <Spinner animation="border" variant="secondary" />
                </div>
            );
        } else if (dataReducer !== 'userFavoritesBookOffers') {
            return (
                    cardsGridData?.length > 1 ?
                    <button className={styles["cg--wrp-btn"]} onClick={showMoreCards}>         
                        <FormattedMessage id="SeeMore" defaultMessage="Zobacz więcej" />
                    </button>
                    :''
            );
        } else if (cardsGridData && cardsGridData.length > 0) {
            return cardsGridData?.length > recordsPerPage &&
                <div className={styles["cg--wrp-pagination"]}>  
                    <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
        }
    
        return null;
    };

    const BookContainer = ({ cardData }) => {
        const { title, authors, price, condition, year, publisher, cover_type, format, page_count, location } = cardData;
        const [bookInfromationAmazonAffiliateInfo, setBookInfromationAmazonAffiliateInfo] = useState(null);

        // Ładowanie informacji o książce z `book_information` na podstawie ISBN w celu uzyskania danych z `amazon_affiliate`
        useEffect(() => {
            const fetchBookInformationData = async () => {
                let details;
                if (cardData.isbn) {
                    const isbn = cardData.isbn;
                    details = await extractBookInformationDetails({isbn});
                }
                if (details && details.items && details.items.length > 0 && details.items[0].amazonAffiliates && details.items[0].amazonAffiliates.length > 0) {
                    setBookInfromationAmazonAffiliateInfo(details.items[0].amazonAffiliates[0]);
                }              
            };
            fetchBookInformationData();
        }, [cardData]);

        // useEffect(() => {
        //     console.log("bookInfromationAmazonAffiliateInfo => ", bookInfromationAmazonAffiliateInfo)
        // }, [bookInfromationAmazonAffiliateInfo]);

        return (
            <div className={styles["book-container"]}>
                <BookImage bookData={cardData} />
                <BookInfo bookData={cardData} bookTitle={title} bookAuthors={authors} bookPrice={price} wantedBook={true} favoriteBook={true} />
                {
                    !limitNumberColumns &&
                    <BookInfo bookCondition={condition} bookPublicationDate={year} bookPublisher={publisher} bookISBN={getISBN(cardData)} />
                }
                {
                    !limitNumberColumns &&
                    <BookInfo bookCoverType={cover_type} bookFormat={format} bookPageCount={page_count}/>
                }
                <BookInfo bookData={cardData} bookLocation={location} bookAmazonAffiliate={bookInfromationAmazonAffiliateInfo}/>
                { 
                    sessionData && sessionData.id === cardData.users_id 
                    && <BookEditViewRemoveBtn bookData={cardData}/> 
                }
            </div>
        )
    };

    const BookImage = ({ bookData }) => {    
        // Inicjalizacja stanu imageUrl
        // const [imageUrl, setImageUrl] = useState(bookData.picture_url ? `${bookData.picture_url}?v=${new Date().getTime()}` : ImagePaths.openBookImg);
        const [imageUrl, setImageUrl] = useState(bookData.picture_url || ImagePaths.openBookImg);
    
        // Aktualizacja imageUrl przy zmianie bookData.picture_url
        useEffect(() => {
            setImageUrl(bookData.picture_url || ImagePaths.openBookImg);
        }, [bookData.picture_url]);
    
        // Obsługa błędu ładowania obrazu
        const handleError = () => {
            if (imageUrl !== ImagePaths.openBookImg) {
                setImageUrl(ImagePaths.openBookImg);
            }
        };
    
        return (
            <div onClick={() => { goToCardDetailsPage(bookData, locale) }} className={styles["book-container-image"]}>
                {/* <Image 
                    // src={imageUrl} 
                    src={`${imageUrl}?v=${new Date().getTime()}`}
                    alt={genAltText(imageUrl)} 
                    width={'100'}
                    height={'150'}
                    className={styles["book-container-image-img"]} 
                    onError={handleError}
                /> */}
                {/* <img
                    src={imageUrl}
                    alt={genAltText(imageUrl)}
                    width={'100'}
                    height={'150'}
                    className={styles["book-container-image-img"]}
                    onError={handleError}
                /> */}
                <LazyLoadImage
                    key={bookData.id}
                    src={imageUrl} // źródło obrazu
                    // alt={genAltText(imageUrl)} // tekst alternatywny
                    alt={bookData.title} // tekst alternatywny
                    width='100'
                    height='150'
                    effect="blur" // efekt ładowania (opcjonalnie)
                    placeholderSrc={ImagePaths.bookBlurImg} // Tutaj umieść ścieżkę do uniwersalnego rozmytego obrazka
                    className={styles["book-container-image-img"]}
                    style={imageUrl === ImagePaths.openBookImg ? { padding: '10px' } : {}}
                    onError={handleError} // obsługa błędów
                />
            </div>
        );
    };

    const BookInfo = ({ bookData, bookTitle, bookAuthors, bookPrice, wantedBook, favoriteBook, bookCondition, bookPublicationDate, bookPublisher, bookISBN, bookCoverType, bookFormat, bookPageCount, bookLocation, bookAmazonAffiliate }) => {
        const { favorites, toggleFavorite, isFavorite } = useFavorite();
        return (
            <div className={styles["book-container-info"]}>
                {   
                    bookTitle && 
                    <h1 className={`${styles['book-container-info-title']}`} onClick={()=> {goToCardDetailsPage(bookData, locale)}} style={{ cursor: 'pointer' }}>
                        {bookTitle}
                    </h1>
                }
                { 
                    bookAuthors &&
                    <div className={styles["book-container-info-hdr"]}>
                        Autor: <span className={styles["book-container-info-txt"]}>
                            {bookAuthors === 'Unknown' ? 'nieznany' : bookAuthors}
                        </span>
                    </div>
                }
                { 
                    bookPrice || bookPrice === 0 ?
                    <div className={styles["book-container-info-hdr"]}>
                        Cena:&nbsp;
                            <span className={styles["book-container-info-txt"]}>
                                <span style={{fontWeight: '600'}}>
                                    {bookPrice.toFixed(2) + " zł"}
                                </span>
                            </span>
                    </div>: ''
                }
                {
                    // !isMyCollectionPage && wantedBook && 
                    wantedBook && sessionData && sessionData.id != bookData.users_id &&
                    <div style={{marginTop: '12px'}}>
                        <div className={`${styles['book-container-info-hdr']}`} onClick={()=> changeIsShownState(true)} style={{cursor: 'pointer'}}>
                            <span className={`${styles['book-container-info-txt']} ${styles['book-container-info-txt-btn']}`}>
                                <FaPlusCircle className={styles["book-container-info-ico"]}/>
                                Dodaj do poszukiwanych
                            </span>
                        </div>
                    </div>
                }
                {
                    favoriteBook && sessionData && sessionData.id != bookData.users_id &&
                    <div style={{marginBottom: '12px'}}>
                        { isFavorite(bookData.id) ? 
                        <div className={`${styles['book-container-info-hdr-fav']}`} onClick={()=> {toggleFavorite(bookData.id)}} style={{cursor: 'pointer'}}>
                            <span className={`${styles['book-container-info-txt-fav']} ${styles['book-container-info-txt-btn-fav']}`}>
                                <FaHeart className={styles["book-container-info-ico-fav"]} />
                                <FormattedMessage
                                    id = "RemoveFromFavorites"
                                    defaultMessage="Usuń z ulubionych"
                                />
                            </span>
                        </div>
                        :
                        <div className={`${styles['book-container-info-hdr']}`} onClick={()=> {toggleFavorite(bookData.id)}} style={{cursor: 'pointer'}}>
                            <span className={`${styles['book-container-info-txt']} ${styles['book-container-info-txt-btn']}`}>
                                <FaHeart className={styles["book-container-info-ico"]} />
                                <FormattedMessage
                                    id = "AddToFavorites"
                                    defaultMessage="Dodaj do ulubionych"
                                />
                            </span>
                        </div>
                        }
                    </div>
                }
                {
                    bookCondition &&
                    <div className={styles["book-container-info-hdr"]}>
                        Stan książki: <span className={styles["book-container-info-txt"]}>{bookCondition}</span>
                    </div>
                }
                {
                    bookPublicationDate &&
                    <div className={styles["book-container-info-hdr"]}>
                        Rok wydania: <span className={styles["book-container-info-txt"]}>{bookPublicationDate}</span>
                    </div>
                }
                {
                    bookPublisher &&
                    <div className={styles["book-container-info-hdr"]}>
                        Wydawca: <span className={styles["book-container-info-txt"]}>{bookPublisher}</span>
                    </div>
                }
                {
                    bookISBN &&
                    <div className={styles["book-container-info-hdr"]}>
                        ISBN: <span className={styles["book-container-info-txt"]}>{bookISBN}</span>
                    </div>
                }
                {
                    bookCoverType &&
                    <div className={styles["book-container-info-hdr"]}>
                        Oprawa: <span className={styles["book-container-info-txt"]}>{bookCoverType}</span>
                    </div>
                }
                {
                    bookFormat &&
                    <div className={styles["book-container-info-hdr"]}>
                        Format: <span className={styles["book-container-info-txt"]}>{bookFormat}</span>
                    </div>
                }
                {
                    bookPageCount &&
                    <div className={styles["book-container-info-hdr"]}>
                        Liczba stron: <span className={styles["book-container-info-txt"]}>{bookPageCount}</span>
                    </div>
                }
                {
                    bookLocation &&
                    <div className={styles["book-container-info-hdr"]}>
                        Dostępne w:
                        <span style={{display: 'flex'}} className={styles["book-container-info-txt"]}>
                            {getLocationName(bookLocation, 20)}
                        </span>
                        <button className={styles["cg--btn-location"]} onClick={()=> {goToCardDetailsPage(bookData, locale)}}>
                            <span className={styles["cg--btn-location-t"]}> 
                                <FaInfoCircle className={styles["cg--ico"]} />
                                <FormattedMessage
                                    id = "ShowDetails"
                                    defaultMessage="Pokaż szczegóły"
                                />
                            </span>    
                        </button>
                    </div>
                }
                {
                    bookAmazonAffiliate
                    && <BookAmazonAffiliate amazonAffiliateInfo={bookAmazonAffiliate}/>
                }
            </div>
        )
    };

    const BookAmazonAffiliate = ({ amazonAffiliateInfo }) => {
        const openInNewTab = (url) => {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }; 

        console.log(amazonAffiliateInfo)

        return (
            <div onClick={() => openInNewTab(amazonAffiliateInfo.item_url)} className={styles["book-container-amazon-affiliate"]}>
                {/* <Image 
                    // src={imageUrl} 
                    src={IconPaths.amazonIcon}
                    alt={genAltText(IconPaths.amazonIcon)} 
                    width={'100'}
                    height={'40'}
                    className={styles["book-container-amazon-affiliate-icon"]} 
                    placeholder="blur"
                    blurDataURL={ImagePaths.amazonBlurImg}
                /> */}
                <LazyLoadImage
                    key={amazonAffiliateInfo.id}
                    src={IconPaths.amazonIcon} // źródło obrazu
                    alt={genAltText(IconPaths.amazonIcon)} // tekst alternatywny
                    width={'100'}
                    height={'40'}
                    effect="blur" // efekt ładowania (opcjonalnie)
                    placeholderSrc={ImagePaths.amazonBlurImg} // Tutaj umieść ścieżkę do uniwersalnego rozmytego obrazka
                    className={styles["book-container-amazon-affiliate-icon"]} 
                />
            </div>
        );
    };

    const BookEditViewRemoveBtn = ({ bookData }) => { 
        const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] = useState(false);
        const [openCardDetailsModal, setOpenCardDetailsModal] = useState(false);

        const handleOpenDeleteConfirmationDialog = useCallback(() => {
            setOpenDeleteConfirmationDialog(true);
        }, []);
    
        const handleCloseDeleteConfirmationDialog = useCallback(() => {
            setOpenDeleteConfirmationDialog(false);

        }, []);

        const handleCloseCardDetailsModal = useCallback(() => {
            setOpenCardDetailsModal(false);
        }, []);
    
        const handleConfirmDeleteConfirmationDialog = useCallback(() => {
            // Tutaj logika usuwania elementu
            if ( bookData && bookData.id) {
                deleteBookOffers(bookData.id); // changeIsShownState(true);
                // changeIsShownState(true); 
            }
            handleCloseDeleteConfirmationDialog();
        }, [bookData, handleCloseDeleteConfirmationDialog]);

        const handleEditBookOffers = useCallback((card, lang) => {      
            if (card?.id) {
                dispatch(setBookOffersId(card.id));
                routerNextRouter.push('/moj-zbior/edytuj-ksiazke'); // changeIsShownState(true); 
                // changeIsShownState(true); 
            }
        }, []);

        const cardDetailsModalDisplayKeys = [
            // `book_offers`
            { key: 'location', isImage: false },
            { key: 'title', isImage: false },
            { key: 'authors', isImage: false },
            { key: 'picture_url', isImage: true, imageSize: { width: 100, height: 150 }, customLabel: <Trans id="PictureUrl" /> },
            { key: 'price', isImage: false },
            { key: 'isbn', isImage: false },
            { key: 'ean', isImage: false },
            { key: 'issn', isImage: false },
            { key: 'condition', isImage: false },
            { key: 'condition_description', isImage: false },
            { key: 'subtitle', isImage: false },
            { key: 'original_title', isImage: false },
            { key: 'collection_title', isImage: false },
            { key: 'publisher', isImage: false },
            { key: 'published_city', isImage: false }, 
            { key: 'issue_number', isImage: false }, 
            { key: 'description', isImage: false },
            { key: 'short_description', isImage: false },
            { key: 'cover_type', isImage: false },
            { key: 'year', isImage: false },
            { key: 'page_count', isImage: false },
            { key: 'format', isImage: false },
            { key: 'weight', isImage: false },
            { key: 'languages', isImage: false },
            { key: 'category', isImage: false },
            { key: 'picture_source', isImage: false },
            { key: 'source', isImage: false },
        ];

        return (
            <>
                <div className={styles["book-container-e-r-sec"]}>
                    {/* Edytuj pozycję */}
                    <div onClick={()=> handleEditBookOffers(bookData, locale)} >
                        <FaPencilAlt 
                            className={styles["book-container-e-r-ico"]} 
                            data-tooltip-id="my-tooltip-edit" 
                            data-tooltip-html={'Edytuj pozycję'}
                        />
                    </div>
                    {/* Pokaż pozycję */}
                    <div onClick={()=> setOpenCardDetailsModal(true)} >
                        <FaEye 
                            className={styles["book-container-e-r-ico"]} 
                            data-tooltip-id="my-tooltip-view" 
                            data-tooltip-html={'Pokaż pozycję'}
                        />
                    </div>
                    {/* Usuń pozycję */}
                    <div onClick={()=> handleOpenDeleteConfirmationDialog()} >
                        <FaTrashAlt 
                            className={styles["book-container-e-r-ico"]} 
                            data-tooltip-id="my-tooltip-remove" 
                            data-tooltip-html={'Usuń pozycję'}
                        />
                    </div>
                </div>
                {/* ReactTooltip */}
                <ReactTooltip 
                    key={new Date() + 'my-tooltip-edit'}
                    id="my-tooltip-edit" 
                    place="top-end" //top top-start top-end right right-start right-end bottom bottom-start bottom-end left left-start left-end
                    className="custom-tooltip" 
                    effect="solid"
                />
                {/* ReactTooltip */}
                <ReactTooltip 
                    key={new Date() + 'my-tooltip-view'}
                    id="my-tooltip-view" 
                    place="top-end" //top top-start top-end right right-start right-end bottom bottom-start bottom-end left left-start left-end
                    className="custom-tooltip" 
                    effect="solid"
                />
                {/* ReactTooltip */}
                <ReactTooltip 
                    key={new Date() + 'my-tooltip-remove'}
                    id="my-tooltip-remove" 
                    place="top-end" //top top-start top-end right right-start right-end bottom bottom-start bottom-end left left-start left-end
                    className="custom-tooltip" 
                    effect="solid"
                />
                
                <DeleteConfirmationDialog
                    open={openDeleteConfirmationDialog}
                    handleClose={handleCloseDeleteConfirmationDialog}
                    handleConfirm={handleConfirmDeleteConfirmationDialog}
                />
                { 
                    openCardDetailsModal &&
                    <CardDetailsModal
                        headerTitle={'Szczegóły pozycji'}
                        card={jsonArrayToObject(bookData)}
                        open={openCardDetailsModal}
                        handleClose={handleCloseCardDetailsModal}
                        displayKeys={cardDetailsModalDisplayKeys}
                    />
                }
            </>
        );
    };

    const renderedCardsGridData = useCallback((book) => {
        // currentCardsGridData.slice(cardIndex, cardIndex + 6).map(book => (
        return book.map((card, i) => (   
            <div key={i} className={styles["cg--wrp"]}>
                {/* testowe zliczanie pozycji */}
                {/* <div style={{position: 'absolute' , fontSize:'small', paddingTop: '4px', marginLeft: '14px'}}>
                    {i+1}/{card.id}
                </div> */}
                <BookContainer key={i} cardData={card} />
            </div>
        ))
    }, []);

    const renderContent = () => {
        if (dataReducer !== 'userFavoritesBookOffers') {
            return renderedCardsGridData(currentCardsGridData);
        } else {
            return renderedCardsGridData(currentRecords);
        }
    };

    const renderSpinner = () => (
        <div className={styles["spn-cnt"]}>
            <Spinner animation="border" variant="secondary" />
        </div>
    );

    // Na potrzeby obsługi po zalogowaniu użytkownika,
    // w przypadku kliknięcia 'Dodaj do ulubionych' lub 'Usuń z ulubionych'
    // useEffect(() => {
    //     if (session) {
    //         const action = localStorage.getItem('postLoginAction');
    //         const id = localStorage.getItem('bookId');

    //         if (action && id) {
    //             toggleFavorite(id);
    //         }

    //         console.log("toggleFavorite action ", action)
    //         console.log("toggleFavorite id ", id)
            
    //         // Wyczyść localStorage
    //         localStorage.removeItem('preLoginRoute');
    //         localStorage.removeItem('shouldRedirect');
    //         localStorage.removeItem('postLoginAction');
    //         localStorage.removeItem('actionId');
    //     }
    // }, [session, toggleFavorite]);

    // debugLog
    // useEffect(() => {
    //     debugLog('CardsGrid session', 'debug', session);
    //     debugLog('CardsGrid sortFieldAndDirection', 'debug', sortFieldAndDirection);
    // }, [session, sortFieldAndDirection]);

    return (
        <>
            { currentCardsGridData?.length > 0 ? (
                <>
                    <div className={styles["cg--wrp-without-box-shadow"]}>
                        {renderContent()}
                    </div>
                    {
                        dataReducer!='recentlyViewedBookOffers' 
                        && renderButtonOrPagination()
                    }
                </>
                ) : (
                    booksIsLoading ? (
                        <div className={styles["cg--wrp"]} style={{ background: "none", boxShadow: "none" }}>
                            {renderSpinner()}
                        </div>
                    ) : null
                )
            }
            <Loading isLoading={loading} isLoadingText={processing}/>
        </>
    );
}

// PropTypes
CardsGrid.propTypes = {
    searchPhrase: PropTypes.string,
    cardsGridData: PropTypes.array.isRequired,
    dataReducer: PropTypes.string.isRequired,
    sortFieldAndDirection: PropTypes.object.isRequired,
    limitNumberColumns: PropTypes.bool,

    coordinates: PropTypes.object,
    session:  PropTypes.object,
    cardData: PropTypes.array,
    bookData: PropTypes.array,
    bookTitle: PropTypes.string,
    bookAuthors: PropTypes.string,
    bookPrice: PropTypes.number,
    wantedBook: PropTypes.bool,
    favoriteBook: PropTypes.bool,
    bookCondition: PropTypes.string,
    bookPublicationDate: PropTypes.string,
    bookPublisher: PropTypes.string,
    bookISBN: PropTypes.string,
    bookCoverType: PropTypes.string,
    bookPageCount: PropTypes.string,
    bookLocation: PropTypes.array,
    bookPrice: PropTypes.number,
    bookFormat: PropTypes.string,
    bookOffersId: PropTypes.number,
    bookAmazonAffiliate: PropTypes.array,
    amazonAffiliateInfo: PropTypes.array,
  // Definicje dla pozostałych właściwości...
};