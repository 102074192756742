// src/components/_common/BugReportModalBox/index.js
"use client";

import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { Button as SendButton, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';

import styles from './css/bug-report-modal-box.module.css';

import { IconPaths } from '@/constants';
import { genAltText } from '@/utils';
import { useLanguage } from '@/contexts/LanguageContext';
import Loading from "@/components/_common/Loading/Loading";
import { Trans } from '@/utils/intlTranslations';
import { useIntl } from 'react-intl';

import debugLog from '@/helpers/debugLog'; 

export const BugReportModalBox = ({ sessionData, openModal, setOpenModal }) => {
    const [loadingBugReport, setLoadingBugReport] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        email: sessionData ? sessionData.email : ''
    });
    const { locale } = useLanguage();

    const intl = useIntl();
    const processing = intl.formatMessage({ id: "Processing" });
    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleClose = () =>  {
        // Resetuj na wartości początkowe
        setFormData({
            title: '',
            description: '',
            email: sessionData ? sessionData.email : ''
        });

        setOpenModal(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingBugReport(true);
        // Walidacja
        console.log("TEST => title => ", JSON.stringify(formData.title))
        console.log("TEST => description => ", JSON.stringify(formData.description))
        if (!formData.title || !formData.description) {
            setLoadingBugReport(false);
            toast.error("Proszę wypełnić wszystkie pola.", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }else{
            // Wyślij dane do API
            try {
                const response = await fetch('/api/user-profile/send-bug-report-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        bugReportSubject: formData.title,
                        bugReportDescription: formData.description,
                        userEmail: formData.email,
                        selectedLang: locale
                    })
                });
    
                if (response.ok) {
                    handleClose();
                    
                    setLoadingBugReport(false);

                    // Resetuj na wartości początkowe
                    setFormData({
                        title: '',
                        description: '',
                        email: sessionData ? sessionData.email : ''
                    });

                    toast.success(
                        <FormattedMessage 
                            id="BugReportModalBoxSendSuccess" 
                            defaultMessage="Dziękujemy za zgłoszenie! Twoja pomoc jest cenna w ulepszaniu naszej aplikacji. Nasz zespół już bierze się za rozwiązanie Twojego problemu i wkrótce się z Tobą skontaktujemy." 
                        />
                    );
                } else {
                    throw new Error('Problem z wysłaniem zgłoszenia');
                }
            } catch (error) {
                toast.error(`Błąd: ${error.message}`);
            }
        }
    };

    // debugLog
    // useEffect(() => {
    //     debugLog('BugReportModalBox sessionData', 'debug', sessionData);
    // }, [sessionData]);

    return (
        <>
            <Dialog
                open={openModal}
                onClose={() => handleClose()}
                aria-labelledby="modal-title"
                PaperProps={{
                    style: {
                    maxHeight: '80vh',
                    marginBottom: '20vh', // Dostosuj wartość zgodnie z wysokością twojego menu
                    },
                }}
            >
                <DialogTitle id="modal-title">
                    <Image 
                        src={IconPaths.bugReportIcon}  
                        alt={genAltText(IconPaths.bugReportIcon)} 
                        width={18} height={20} 
                        className={styles["bug-report--ico"]} 
                    />
                    <FormattedMessage
                        id = "BugReport"
                        defaultMessage="Zgłoś błąd"
                    />
                </DialogTitle>
                <DialogContent style={{ paddingBottom: '0px'}}>
                    <div className={styles["bug-report--sec"]}>
                        <div className={styles["bug-report--sec-hdr"]}>
                            <FormattedMessage
                                id = "HelpUsImproveOurApp"
                                defaultMessage="Pomóż Nam Poprawić Naszą Aplikację"
                            />
                        </div>
                    </div>

                    <div className={styles["bug-report--sec"]}>
                        <form onSubmit={handleSubmit} className={styles["bug-report--sec-form"]}>
                            <TextField 
                                fullWidth 
                                label="Tytuł błędu" 
                                name="title" 
                                placeholder="Wpisz tytuł błędu..."
                                onChange={handleChange} 
                            />
                            <TextField
                                fullWidth
                                label="Opis"
                                name="description"
                                placeholder="Opisz szczegółowo problem, który napotkałeś..."
                                multiline
                                minRows={4} // Dostosuj według potrzeb
                                onChange={handleChange}
                            />
                            <TextField 
                                fullWidth 
                                label="Twój Email" 
                                name="email" 
                                value={formData.email} // Użyj wartości stanu jako wartości pola
                                // defaultValue="przykladowy@email.com" // Ustaw wartość domyślną
                                // onChange={handleChange}
                                disabled // Blokuje możliwość edycji pola 
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                            />
                            <SendButton 
                                type="submit" 
                                fullWidth
                                className={styles["bug-report--sec-form-btn"]}
                            >
                                <FormattedMessage
                                    id = "Send"
                                    defaultMessage="Wyślij"
                                />
                            </SendButton>
                        </form>
                    </div>

                    <hr />

                    <div className={styles["bug-report--wrp"]}>
                        <div className={styles["bug-report--sec-txt"]}>
                            <FormattedMessage
                                id = "HelpUsImproveOurAppBottomText"
                                defaultMessage="Przed wysłaniem zgłoszenia upewnij się, że opis błędu jest jak najbardziej szczegółowy. Pomaga nam to w szybszym zidentyfikowaniu i rozwiązaniu problemu."
                            />
                        </div>
                        <div className={styles["bug-report--sec-txt"]}>
                            <FormattedMessage
                                id = "EveryReportIsImportantToUs"
                                defaultMessage="Każde zgłoszenie jest dla nas ważne. Działamy nieustannie, aby nasza aplikacja była jak najlepsza i wolna od błędów."
                            />
                        </div>
                    </div>

                    <hr />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Zamknij</Button>
                </DialogActions>
            </Dialog>
            <Loading isLoading={loadingBugReport} isLoadingText={processing}/>
        </>
    );
}

// PropTypes
BugReportModalBox.propTypes = {
    sessionData: PropTypes.object,
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
  // Definicje dla pozostałych właściwości...
};